<template>
  <section class="category">
    <Loader :loading="showLoader" />
    <div class="page-header mb-3">
      <h3 class="page-title" v-if="add_Category.parent_id == 0">Add Category</h3>
      <h3 class="page-title" v-else>Add Sub Category</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/view-category" class="text-primary">Category
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Category</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="category">
      <div class="row">
        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="add_Category.name" id="name" name="name" class="form-control" :class="{
                    'is-invalid': submitted && $v.add_Category.name.$error,
                  }" />
                  <div v-if="submitted && !$v.add_Category.name.required" class="invalid-feedback">
                    Name is required
                  </div>
                </div>
              </div>

              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Image
                  </label>
                  <div class="form-group">
                    <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>

                    <chooseImageProduct @imageId="setImageId"></chooseImageProduct>
                <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions" :useCustomSlot="true"
                  v-on:vdropzone-success="success" @click="removeThisFile(UploadFile)" @vdropzone-sending="sendImages">
                  <div class="dropzone-custom-content">
                    <div class="dropzone-custom-content">
                      <div v-if="bannerImg != ''">
                        <div class="image-size-dropzone" v-for="bannerImg in bannerImg" :key="bannerImg.id">
                          <img :src="`${imageApiURL}/${bannerImg.thumb_s}`" class="show-img img-fluid" />
                        </div>
                        <div class="subtitle pt-1 pb-0">
                          <span> Change </span>
                          <span style="color: #eb2027"> Browse</span>
                        </div>
                      </div>
                      <div v-if="bannerImg == ''" class="pt-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                          fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-upload" color="">
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        <div class="subtitle pt-1 pb-5">
                          <span> Drop File or </span>
                          <span style="color: #eb2027"> Browse</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-dropzone>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">

              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Sort </label>

                <div class="form-group">
                  <input type="number" class="form-control" v-model="add_Category.sort" />
                </div>

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                  </label>
                  <div class="form-group ">
                    <textarea class="form-control" rows="4" v-model="add_Category.description"></textarea>
                  </div>
                </div>
              </div>
              <div class="form-group mb-0 ">
                <label for=""> Status </label>
                <div class="form-group  mb-0">
                  <toggle-button :value="true" v-model="add_Category.active"
                    :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>
  
  </section>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import { catalogApi } from "../../../api";
import Loader from "../../../components/Loader";
import { required } from "vuelidate/lib/validators";
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
import chooseImage from "../../../components/chooseImage";
import chooseImageProduct from "../../../components/chooseImageProduct";
export default {
  name: "add_category",
  components: {
    Loader,
    chooseImage,
    chooseImageProduct,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      showLoader: false,
      imageApiURL,
      add_Category: {
        parent_id: parseInt(this.$route.params.id),
        active: true,
      },
      submitted: false,
      bannerImg: "",
      imagesId: [],
      bannerImg: [],
    };
  },
  mounted() { },
  validations: {
    add_Category: {
      parent_id: {},
      name: { required },
    },
    submitted: false,
  },
  methods: {
    sendImages(file, xhr, formData) {
      let products = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", products);
    },
    setImageId(selectedId) {
      this.imagesId = selectedId;
      selectedId.forEach((item) => {
        this.showLoader = true;
        axios.get(`${mediaApi}/files/${item}`).then((response) => {
          let a = response.data.data[0];
          this.bannerImg.push(a)

          this.showLoader = false;
        });
      });

    },
    success(file, response) {
      this.add_Category.image_id = response.data.id;
      this.bannerImg = "";
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async category() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.bannerImg) {
        this.add_Category.image_id = this.bannerImg.id;
      }

      if (this.add_Category.image_id) {
        this.add_Category.image_id = parseInt(this.add_Category.image_id);
      }

      if (this.add_Category.active === true) {
        this.add_Category.active = 1;
      } else {
        this.add_Category.active = 0;
      }
      for (var key in this.add_Category) {
        if (this.add_Category.hasOwnProperty(key)) {
          var val = this.add_Category[key];
          if (val === 0) {
            delete this.add_Category[key];
          }
        }
      }
      this.showLoader = true;
      const data = await catalogApi.addCategory({
        ...this.add_Category,
      });
      this.showLoader = false;
      this.$router.push("/catalog/view-category");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 8px 20px !important;
}

.form-group label {
  text-align: right;
}

.form-group label span {
  font-size: 10px;
}
</style>
